<template>
  <v-row class="fill-height justify-center align-center" v-if="isLoading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-row>
  <v-container class="fill-height justify-center align-center" v-else-if="showAlertsContainer">
    <v-layout align-center justify-center column>
      <v-card class="mb-3">
        <v-card-title>Attention Store Staff</v-card-title>
      </v-card>
      <v-alert v-if="isInvalidKey" type="error">
        Invalid Invitation. For assistance, please contact Sony Pro Support at 800.883.6817.
      </v-alert>
      <v-alert v-else-if="isExpired" type="error">
        This QR code is expired.
      </v-alert>
      <v-alert v-else-if="isRedeemed || !hasAvailableSubmissions" type="info">
        This QR code has been redeemed.
      </v-alert>
    </v-layout>
  </v-container>
  <v-container v-else>
    <v-card>
      <v-card-title>Exclusive Promotion Invitation</v-card-title>
      <v-card-text>
        <p>Name: {{ participantName }}</p>
        <p>Email: {{ participantEmail }}</p>
        <p>Country: {{ participantCountry }}</p>
        <p>City, State: {{ participantLocation }}</p>
        <p>Valid until: {{ promotionInvitationExpirationDate }}</p>
        <v-alert v-if="isRedeemable" type="info">
          <p>STORE STAFF ONLY</p>
          <p>1. Confirm Customer's ID matches above</p>
          <p class="mb-0">2. Click REDEEM to activate discount</p>
          <v-card-actions class="justify-end">
            <v-btn color="primary" @click="openDialog" :loading="isRedeeming" :disabled="!isAvailableToRedeem">
              Redeem
            </v-btn>
          </v-card-actions>
        </v-alert>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Redeem</v-card-title>
        <v-card-text class="text-h6"
          ><strong>Attention:</strong> REDEEM is exclusively for store staff. Customers, please click CANCEL to avoid
          deactivating your one-time discount</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="redeemPromotion" :loading="isRedeeming">Redeem</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Exclusive Discounts -->
    <v-row class="d-flex align-stretch">
      <v-col cols="12" md="5" class="d-flex py-0" fill-height>
        <v-card class="d-flex flex-column mt-4 flex-grow-1">
          <v-card-title>Exclusive Discounts</v-card-title>
          <v-card-text class="flex-grow-1">
            <p class="text-h6 mb-0">20% off retail of below eligible Sony cameras, lenses and accessories.</p>
            <v-row>
              <v-col cols="12" md="4" class="py-0">
                <v-subheader class="font-weight-bold text-h7 text-decoration-underline pa-0">Cameras</v-subheader>
                <v-list outlined dense>
                  <v-list-item v-for="camera in displayCameras" :key="camera.id">
                    <v-list-item-content>{{ camera.model }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-subheader class="font-weight-bold text-h7 text-decoration-underline pa-0">Lenses</v-subheader>
                <v-list outlined dense>
                  <v-list-item v-for="lens in displayLenses" :key="lens.id">
                    <v-list-item-content>{{ lens.model }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-subheader class="font-weight-bold text-h7 text-decoration-underline pa-0">Accessories</v-subheader>
                <v-list outlined dense>
                  <v-list-item v-for="accessory in displayAccessories" :key="accessory.id">
                    <v-list-item-content>{{ accessory.model }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2" class="d-flex align-center justify-center pb-0">
        <div class="text-h5 font-weight-bold white--text">PLUS</div>
      </v-col>

      <!-- Trade-In Discounts -->

      <v-col cols="12" md="5" class="d-flex py-0" fill-height>
        <v-card class="mt-4 d-flex flex-column flex-grow-1">
          <v-card-title>Trade-In Discounts</v-card-title>
          <v-card-text class="flex-grow-1">
            <p class="text-h6">
              Apply trade-in value of the used equipment determined by dealer
            </p>
            <div class="text-center font-weight-bold my-4">PLUS TRADE-IN BONUS</div>
            <p class="text-h6">
              If end user trades in used equipment, they can qualify for a Trade-In BONUS
            </p>
            <ul>
              <li>
                <p class="text-h6">
                  You get an extra $1,000 if you trade in and buy 1 eligible Sony Camera Body AND 1 eligible Sony Lens
                </p>
              </li>
              <div class="text-center font-weight-bold my-4">OR</div>
              <li>
                <p class="text-h6">
                  You get an extra $2,000 if you trade in and buy 1 eligible Sony Camera Body AND 2 eligible Sony Lenses
                </p>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UtilService from "../gapp-components/services/util.service.js";

export default {
  name: "PromotionInvitation",
  data() {
    return {
      isLoading: false,
      isRedeemed: false,
      isRedeeming: false,
      isInvalidKey: false,
      showDialog: false,
      personalizedInfo: {
        participantEmail: "",
        participantFirstName: "",
        participantLastName: "",
        country: "",
        city: "",
        region: ""
      },
      promotionInvitation: {
        countSubmissions: 0,
        limitSubmissions: 0,
        isEffective: false,
        expirationDate: "",
        createdDate: ""
      },
      promotion: {},
      cameras: {
        US: [
          { id: 1, model: "ILCE1/B Alpha 1" },
          { id: 2, model: "ILCE9M3B Alpha 9 III" },
          { id: 3, model: "ILCE7RM5/B Alpha 7R V" },
          { id: 4, model: "ILCE7SM3/B Alpha 7S III" }
        ],
        CA: [
          { id: 1, model: "ILCE1/B Alpha 1" },
          { id: 2, model: "Alpha 9 II camera body" },
          { id: 3, model: "ILCE7RM5/B Alpha 7R V" },
          { id: 4, model: "ILCE7SM3/B Alpha 7S III" }
        ]
      },
      lenses: {
        US: [
          { id: 1, model: "SEL1635GM" },
          { id: 2, model: "SEL1635GM2" },
          { id: 3, model: "SEL2470GM" },
          { id: 4, model: "SEL2470GM2" },
          { id: 5, model: "SEL24105G/2" },
          { id: 6, model: "SEL70200GM" },
          { id: 7, model: "SEL70200GM2" },
          { id: 8, model: "SEL50F12GM" },
          { id: 9, model: "SEL50F14GM" },
          { id: 10, model: "SEL24F14GM" },
          { id: 11, model: "SEL35F14GM" },
          { id: 12, model: "SEL100400GM" },
          { id: 13, model: "SEL200600G" }
        ],
        CA: [
          { id: 1, model: "SEL1635GM" },
          { id: 2, model: "SEL1635GM2" },
          { id: 3, model: "SEL2470GM" },
          { id: 4, model: "SEL2470GM2" },
          { id: 5, model: "SEL24105G2" },
          { id: 6, model: "SEL70200GM" },
          { id: 7, model: "SEL70200GM2" },
          { id: 8, model: "SEL50F12GM" },
          { id: 9, model: "SEL50F14GM" },
          { id: 10, model: "SEL24F14GM" },
          { id: 11, model: "SEL35F14GM" },
          { id: 12, model: "SEL100400GM" },
          { id: 13, model: "SEL200600G" }
        ]
      },
      accessories: {
        US: [
          { id: 1, model: "VGC5 Vertical Grip" },
          { id: 2, model: "VGC4EM Vertical Grip" },
          { id: 3, model: "NPFZ100 Battery" },
          { id: 4, model: "HVLF60RM2 Flash GN60" },
          { id: 5, model: "HVLF46RM Flash GN46" },
          { id: 6, model: "CEAG80T 80GB Card" },
          { id: 7, model: "CEAG160T 160GB Card" },
          { id: 8, model: "CEAG320T 320GB Card" },
          { id: 9, model: "CEAG640T 640GB Card" },
          { id: 10, model: "CEAM960T 960GB Card" },
          { id: 11, model: "CEAM1920T 1920GB Card" }
        ],
        CA: [
          { id: 1, model: "VGC5 Vertical Grip" },
          { id: 2, model: "VGC4EM Vertical Grip" },
          { id: 3, model: "NPFZ100 Battery" },
          { id: 4, model: "HVLF60RM2 Flash GN60" },
          { id: 5, model: "HVLF46RM Flash GN46" },
          { id: 6, model: "CEAG80T 80GB Card" },
          { id: 7, model: "CEAG160T 160GB Card" },
          { id: 8, model: "CEAG320T 320GB Card" },
          { id: 9, model: "CEAG640T 640GB Card" },
          { id: 10, model: "CEAM960T 960GB Card" },
          { id: 11, model: "CEAM1920T 1920GB Card" }
        ]
      }
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["selectedClient"]),
    participantName() {
      return `${this.personalizedInfo.participantFirstName} ${this.personalizedInfo.participantLastName}` || "-";
    },
    participantEmail() {
      return this.personalizedInfo.participantEmail || "-";
    },
    participantCountry() {
      return this.personalizedInfo.country || "-";
    },
    participantLocation() {
      const { city, region } = this.personalizedInfo;
      return `${city ? `${city}` : ""}${region ? `, ${region}` : ", -"}`;
    },
    promotionInvitationExpirationDate() {
      return (
        UtilService.formatDateClient(this.promotionInvitation.expirationDate, "MM/DD/YYYY", this.selectedClient) || "-"
      );
    },
    displayCameras() {
      return this.cameras[this.personalizedInfo.country] || this.cameras["US"];
    },
    displayLenses() {
      return this.lenses[this.personalizedInfo.country] || this.cameras["US"];
    },
    displayAccessories() {
      return this.accessories[this.personalizedInfo.country] || this.cameras["US"];
    },
    hasAvailableSubmissions() {
      return this.promotionInvitation.limitSubmissions > this.promotionInvitation.countSubmissions;
    },
    isRedeemable() {
      return this.promotionInvitation.isEffective && this.hasAvailableSubmissions;
    },
    isAvailableToRedeem() {
      return this.promotionInvitation.isEffective && this.hasAvailableSubmissions && !this.isRedeeming;
    },
    showAlertsContainer() {
      return this.isInvalidKey || this.isExpired || !this.hasAvailableSubmissions || this.isRedeemed;
    },
    isExpired() {
      return !this.promotionInvitation.isEffective;
    }
  },
  methods: {
    fetchData() {
      const promotionId = this.$route.params.promotionId;
      const promotionInvitationKey = this.$route.query.invitationKey;

      this.isLoading = true;
      this.$api
        .get("/api/promotions/" + promotionId + "/public")
        .then(({ data }) => {
          this.promotion = data.promotion;

          this.getPublicInfoByInvitationKey(promotionInvitationKey);
        })
        .catch(error => {
          this.isInvalidKey = true;
          console.error("Error verifying promotion or fetching data:", error);
          this.isLoading = false;
        });
    },
    getPublicInfoByInvitationKey(promotionInvitationId) {
      this.$claim
        .verifyPromotionInvitationKey(promotionInvitationId, true)
        .then(response => {
          const promotionData = response.data;
          this.personalizedInfo = {
            participantEmail: promotionData.participantEmail,
            participantFirstName: promotionData.participantFirstName,
            participantLastName: promotionData.participantLastName,
            country: promotionData.participantAddress.countryIsoAlpha2,
            city: promotionData.participantAddress.city,
            region: promotionData.participantAddress.regionIsoAlpha2
          };

          this.promotionInvitation = {
            countSubmissions: promotionData.countSubmissions,
            limitSubmissions: promotionData.limitSubmissions,
            isEffective: promotionData.effective,
            expirationDate: promotionData.expirationDate,
            createdDate: promotionData.createdDate
          };
        })
        .catch(error => {
          if (error?.response?.data?.errorMessage.includes("Promotion Invitation is not effective")) {
            this.promotionInvitation.isEffective = false;
          } else {
            this.isInvalidKey = true;
            console.error("Error verifying invitation or fetching data:", error);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    redeemPromotion() {
      this.isRedeeming = true;
      if (this.hasAvailableSubmissions) {
        this.$api
          .post(`api/promotionInvitations/${this.$route.query.invitationKey}/increaseSubmission/public`)
          .then(response => {
            const promotionData = response.data;
            this.promotionInvitation = {
              countSubmissions: promotionData.countSubmissions,
              limitSubmissions: promotionData.limitSubmissions,
              isEffective: promotionData.effective,
              expirationDate: promotionData.expirationDate,
              createdDate: promotionData.createdDate
            };
            this.isRedeemed = true;
          })
          .catch(error => {
            console.error("Error redeeming the promotion:", error);
          })
          .finally(() => {
            this.closeDialog();
            this.isRedeeming = false;
          });
      } else {
        this.closeDialog();
        this.isRedeeming = false;
      }
    }
  }
};
</script>
